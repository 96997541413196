.landingCopy {
    padding: 0 0 48px 0;
}

.visualCopy {
    padding: 0 0 24px 0;
}

.landingVisual {
    max-width: 100%;
    padding: 0 0 48px 0;
}