body {
    background-color: #fff !important;
    overflow: overlay;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #C51416; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #C51416; 
  }

  html, body {

    height: 100%;
    
    padding: 0;
    
    margin: 0;
    
  }